import dayjs from "dayjs";

import { DATE_UNIT, PERIOD } from "~/constants/dates";
import { Range } from "~/declarations/filters";
import { SubjectGroup } from "~/declarations/models/Subject";
import {
  PERMISSION_CODENAME,
  UserPermission
} from "~/declarations/models/User";
import { getCachedFirstSeenDate } from "~/utils/cachedFirstSeenDate";

export enum FILTER_TYPE {
  period,
  scale,
  lesson,
  subject,
  student,
  search,
  minute,
  studentRating,
  tutorRating
}

export const ALL_SUBJECTS = "All subjects";

export const INIT_SUBJECT_VARIANTS = [
  {
    name: ALL_SUBJECTS,
    id: -1,
    selectable: false
  }
];

export const getSubjects = (data: SubjectGroup[]) => {
  const subjects: SubjectGroup[] = [...INIT_SUBJECT_VARIANTS, ...data];
  subjects.forEach(s => {
    if (s.name === "Legacy Subjects") {
      let newId = -1;
      s.id = newId;
      s.children = s.children?.map(c => {
        newId--;
        return { ...c, id: newId };
      });
    }
  });
  return subjects;
};

export enum SCALE {
  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly"
}

export enum LESSON {
  moderated = "moderated",
  pending = "pending"
}

export function scaleFromString(value: string): SCALE {
  switch (value) {
    case SCALE.hourly:
      return SCALE.hourly;
    case SCALE.daily:
      return SCALE.daily;
    case SCALE.weekly:
      return SCALE.weekly;
    case SCALE.monthly:
      return SCALE.monthly;
  }
  throw new Error(`invalid scale value ${value}`);
}

export interface DropDownFilterItem {
  label: string;
  value: string;
  description?: string;
}

export enum SESSION {
  moderated = "moderated",
  all = "all"
}

export function sessionFromString(value: string): SESSION {
  switch (value) {
    case SESSION.all:
      return SESSION.all;
    case SESSION.moderated:
      return SESSION.moderated;
  }
  throw new Error(`invalid session value ${value}`);
}

export const SESSION_VARIANTS: DropDownFilterItem[] = [
  {
    label: "No",
    value: SESSION.moderated,
    description:
      "By default, we will show sessions that have already been moderated and reviewed by the Pear Deck Tutor staff. Each session takes about 3-4 days to be reviewed, so the data shown might be less than the actual total amount."
  },
  {
    label: "Yes",
    value: SESSION.all,
    description:
      "To get a more accurate data of the numbers and graphs, include the ones pending."
  }
];

export const SCALE_VARIANTS: DropDownFilterItem[] = [
  {
    label: "Hourly",
    value: SCALE.hourly
  },
  {
    label: "Daily",
    value: SCALE.daily
  },
  {
    label: "Weekly",
    value: SCALE.weekly
  },
  {
    label: "Monthly",
    value: SCALE.monthly
  }
];

export const PERMISSION_VARIANTS: UserPermission[] = [
  {
    name: "Lessons",
    codename: PERMISSION_CODENAME.lessons
  },
  {
    name: "Writing Lab",
    codename: PERMISSION_CODENAME.writingLabs
  },
  {
    name: "Students",
    codename: PERMISSION_CODENAME.students
  },
  {
    name: "Reports",
    codename: PERMISSION_CODENAME.reports
  },
  {
    name: "User Management",
    codename: PERMISSION_CODENAME.manageUsers
  }
];

export const PER_PAGE = [25, 50, 100];

function makeNDaysRange(n: number): () => Range {
  return (): Range => {
    const tillDt = dayjs().endOf(DATE_UNIT.day);
    const fromDt = tillDt.subtract(n, DATE_UNIT.day).add(1, DATE_UNIT.ms);
    return [fromDt, tillDt];
  };
}

export const RANGE_GETTERS = {
  [PERIOD.last7Days]: makeNDaysRange(7),
  [PERIOD.last90Days]: makeNDaysRange(90),
  [PERIOD.last30Days]: makeNDaysRange(30),
  [PERIOD.last365Days]: makeNDaysRange(365),
  [PERIOD.allTime]: (): Range => {
    const tillDt = dayjs().endOf(DATE_UNIT.day);
    const fromDt = getCachedFirstSeenDate() ?? dayjs(0);
    return [fromDt, tillDt];
  },
  // actual ranges calculated in getSchoolYearRange when selected- see FilterToolbar.tsx
  [PERIOD.currentYear]: undefined,
  [PERIOD.previousYear]: undefined,
  [PERIOD.custom]: undefined
};

export const customPeriods = [
  PERIOD.custom,
  PERIOD.currentYear,
  PERIOD.previousYear
];

export enum SORT {
  desc = "desc",
  asc = "asc",
  noSort = "noSort"
}

export const gradeOptions = [
  { value: "all", label: "All grades" },
  { value: "0", label: "Kindergarten" },
  { value: "1", label: "1st grade" },
  { value: "2", label: "2nd grade" },
  { value: "3", label: "3rd grade" },
  { value: "4", label: "4th grade" },
  { value: "5", label: "5th grade" },
  { value: "6", label: "6th grade" },
  { value: "7", label: "7th grade" },
  { value: "8", label: "8th grade" },
  { value: "9", label: "9th grade" },
  { value: "10", label: "10th grade" },
  { value: "11", label: "11th grade" },
  { value: "12", label: "12th grade" }
];

export const ratingOptions = [
  { value: "all", label: "All ratings" },
  { value: "1", label: "1 star" },
  { value: "2", label: "2 stars" },
  { value: "3", label: "3 stars" },
  { value: "4", label: "4 stars" },
  { value: "5", label: "5 stars" }
];
